'use strict';

/**
 * 
 * SVG mask calculation
 * 
 */

var UTILS = UTILS || {};

UTILS.setOriginSizesOfSvgMaskImages = function setOriginSizesOfSvgMaskImages() {
    $('.js-svg-skew').each(function() {
        var self = this;
        var svg = $(this).find('svg');

        var myPicture = svg.find('image');
        var address = myPicture.attr('xlink:href');
        var myPictureImage = new Image();

        var originWidth;// = myPictureImage.width;
        var originHeight;// = myPictureImage.height;

        myPictureImage.onload = function () {
            // after this, Canvas’ origin-clean is DIRTY
        };

        myPictureImage.src = address;

        originWidth = myPictureImage.width.length ? myPictureImage.width : 3000;
        originHeight = myPictureImage.height.length ? myPictureImage.height : 3000;

        svg.attr('height', originHeight + 'px').attr('width', originWidth + 'px');

        svg[0].setAttribute('viewBox', '0 0 ' + originWidth + ' ' + originHeight);

        svg.find('mask').attr('height', originHeight ).attr('width', originWidth );

        myPicture.attr('height', originHeight ).attr('width', originWidth );
    });
};


UTILS.svgCalculation = function svgCalculation() {
    $('.js-svg-skew').each(function() {
        var self = this,
            originH = $(self).find('svg').attr('height').replace('px','')*1,
            originW = $(self).find('svg').attr('width').replace('px','')*1,
            actualWidth =  UTILS._screenSize().x,
            calculatedHeight;

        calculatedHeight = (originH * actualWidth) / originW;
        calculatedWidth = (originW * calculatedHeight) / originH;
    });
};


UTILS.svgCenter = function svgCenter() {
    $('.js-svg-skew').each(function() {
        var self = this,
            originH = $(self).find('svg').attr('height').replace('px','')*1,
            originW = $(self).find('svg').attr('width').replace('px','')*1,
            actualDeviceWidth =  UTILS._screenSize().x,
            actualDeviceHeight = UTILS._screenSize().y,

            differentY,
            differentX;

        differentY = (actualDeviceHeight - originH) / 2;
        differentX = (actualDeviceWidth - originW) / 2;

        $(self).find('svg image').attr('x', differentX + 'px').attr('y', differentY + 'px');
        $(self).find('svg .innerCircle').attr('cx', (actualDeviceWidth / 2) + 'px').attr('cy', (actualDeviceHeight / 2) + 'px');
    });
};
