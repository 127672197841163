'use strict';

/**
 * 
 * Scroll To ID 
 * jquery needed
 * 
 */

var UTILS = UTILS || {};

UTILS.scrollToID = function scrollToID(id, context = 'html,body') {
    var path = window.location.pathname;
    var offSet = 0;
    var x = UTILS._screenSize().x;

    // if (x < 768) {
    //     offSet = 60;
    // }
    var targetOffset = $(id).offset().top - offSet;

    $(context).animate({scrollTop:targetOffset}, 600);
};

UTILS.hashAnchorClick = function hashAnchorClick() {
    $('a[href*="#"]:not(.js-scroll-to)').on('click', function (e) {

        var target = this.hash,
            hashValue = target.substr(target.indexOf("#"));

        if (hashValue.length) {
            UTILS.scrollToID( hashValue );
        }

        return false;
    });

    $('.js-fullpage-prev-section').on('click', function (e) {
        $.fn.fullpage.moveSectionUp();
        return false;
    });

    $('.js-fullpage-next-section').on('click', function (e) {
        $.fn.fullpage.moveSectionDown();
        return false;
    });

    $('.js-pilingpage-prev-section').on('click', function (e) {
        $.fn.pagepiling.moveSectionUp();
        return false;
    });

    $('.js-pilingpage-next-section').on('click', function (e) {
        $.fn.pagepiling.moveSectionDown();
        return false;
    });

    return false;
};
