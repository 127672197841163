'use strict';

/**
 * 
 * Load videos
 * 
 */

var UTILS = UTILS || {};

UTILS.loadVideos = function() {
    const videos = document.querySelectorAll('video');

    for (let i = 0; i<videos.length; i++) {
        let sourceOfVideo = videos[i].querySelectorAll('source[data-src]');

        if (sourceOfVideo.length) {
            for (let vI = 0; vI<sourceOfVideo.length; vI++) {
                if ( sourceOfVideo[vI].getAttribute('data-src') ) {
                    sourceOfVideo[vI].setAttribute('src', sourceOfVideo[vI].getAttribute('data-src'));
                }
            }

            if (!videos[i].classList.contains('js-do-not-playsinline')) {
                videos[i].setAttribute('playsinline', true);
            }

            if ( videos[i].getAttribute('data-poster') ) {
                videos[i].setAttribute('poster', videos[i].getAttribute('data-poster'));
                videos[i].removeAttribute('data-poster');
            }
            
            videos[i].load();
        }
    }
};

UTILS.playVideos = function() {
    const videos = document.querySelectorAll('video');

    for (let i = 0; i<videos.length; i++) {
        videos[i].play();
    }
};

UTILS.stopVideos = function() {
    const videos = document.querySelectorAll('video');
    for (let i = 0; i<videos.length; i++) {
        videos[i].pause();
    }
};
